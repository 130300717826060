.rotateComponent{
    animation: rotate 20s infinite linear;
    
}

@keyframes rotate{
    50%{
        transform: rotateZ(180deg);
    }
    100%{
        transform: rotateZ(360deg);
    }
}